import { Link } from "gatsby"
import React from "react"

import logo from './logo.svg';

const Header = () => (
  <div className="flex flex-col justify-between px-6 py-8 space-y-8 md:space-y-0 md:flex-row md:px-10 md:py-10">
    <div className="flex flex-row ">
      <div>
        <img
          src={logo}
          alt="logo"
          className="h-8 transition duration-300 transform hover:scale-105"
        />
      </div>
      <a className="font-logo font-semibold text-white text-lg px-2">MINMAX</a>
    </div>
  </div>
)

export default Header
