import React from "react"

const Hero = () => (
  <div className="flex flex-col items-center w-full max-w-xl px-8 mx-auto mt-8 text-white  mb-10 md:px-0">
    <div className="flex md:flex-row flex-col">
      <div className="border-saas flex-row border-b pb-2 md:mb-0 mb-4 md:mx-2 mx-14 text-center text-3xl font-bold sliding-vertical ">
        <div className="text-center text-white md:-my-0 -my-8">
          <span className="-mx-14">utilities</span>
          <span className="-mx-16">buildings</span>
          <span className="-mx-14">vehicles</span>
          <span className="-mx-20">appliances</span>
          <span className="-mx-20">equipment</span>
        </div>
      </div>
      <div className="font-logo flex-row text-3xl pb-2 md:-mx-0 mx-14 md:-mt-0 -mt-2 font-bold">
        as a service
      </div>
    </div>
    <span className="mt-8 text-sm font-light text-center font-text">
     A <b>better platform</b> for IoT <b>developers</b>, device <b>makers</b>, and <b>operators</b>. Stop shipping dashboards. Start collaborating with <b>real data</b>.
    </span>
  </div>

)

export default Hero
