import React, { FunctionComponent } from "react"

import classnames from "classnames"

import styled, { keyframes } from "styled-components"

import SEO from "./SEO"
import NewsletterSubscribe from "./NewsletterSubscribe"
import Header from "./Header"
import Footer from "./Footer"


const Layout: FunctionComponent<{ pageName?: string, className?: string }> = ({ children, pageName, ...props }) => {
    return (
        <div {...props} className={classnames(props.className, "relative text-opacity-10  overflow-x-hidden")}>
            
            <div className="absolute  h-screen w-full opacity-20" /> { /* TODO: radial gradient with styled.div */ }
            <div className="relative">
                <Header />
                {children}
                <NewsletterSubscribe/>
                <Footer/>
            </div>

        </div>
    )
}

export default Layout
