import React from "react"

const Footer = () => (
  <div>
    <div className=" justify-left ml-6 w-full text-xs  text-gray-400">
      <span>© MinMax, 2023. All rights reserved.</span>
    </div>
  </div>
)

export default Footer
